import { Grid, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { FEATURE_LIST } from "../../../data/StaticList";
import Image from "next/image";

const Features: React.FC = () => {
  return (
    <Grid
      sx={{
        minHeight: { xs: "70vh", md: "100vh" },
        display: { xs: "flex", md: "none" },
      }}
      style={{
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        padding: "48px 24px",
        top: "0",
        position: "relative",
        background: "white",
      }}
    >
      {/* CARD_WRAPPER */}
      <Grid
        sx={{
          display: { xs: "block", md: "flex" },
          width: { xs: "100%", md: "90%" },
        }}
        style={{
          height: "90%",
          margin: "auto",
          flexWrap: "wrap",
          padding: "2%",
          justifyContent: "space-around",
          alignItems: "center",
          overflowY: "scroll",
        }}
        className="container"
      >
        {/* LEFT_CARD */}
        <Grid
          sx={{ width: { xs: "100%", md: "55%" } }}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            
            sx={{
              fontSize: { xs: "24px", md: "40px" },
              textAlign: { xs: "center", md: "left" },
              fontWeight: "700",
            }}
          >
            Unlock extra income for all your needs
          </Typography>
        </Grid>

        {/* RIGHT_CARD_PARENT */}
        <Grid sx={{ width: { xs: "100%", md: "40%" }, margin: "auto" }}>
          {/* RIGHT_CARD */}
          <Carousel
            autoPlay={true}
            interval={4000}
            infiniteLoop={true}
            showStatus={false}
            showThumbs={false}
            swipeable={false}
          >
            {FEATURE_LIST.map((item, i) => (
              <Grid
                sx={{
                  display: { xs: "flex", md: "none" },
                  minHeight: "380px",
                  width: "250px",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "8px",
                  margin: { xs: "auto", md: "0px 15px" },
                }}
                key={i}
              >
                <Grid
                  container
                  sx={{
                    height: "200px",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src={item.staticIcon}
                    alt={item.alt}
                    style={{ objectFit: "contain" }}
                    loading="lazy"
                  />
                </Grid>
                <>
                  <Typography  fontWeight="bold" fontSize="20px">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" fontSize="14px" fontWeight={500}>
                    {item.content}
                  </Typography>
                </>
              </Grid>
            ))}
          </Carousel>
        </Grid>
      </Grid>

      {/* BACKGROUND SVG */}
      <Grid
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          display: { xs: "none", md: "block" },
          height: { xs: "150px", md: "215px" },
          width: { xs: "200px", md: "300px" },
        }}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 300 215"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.05"
            cx="57"
            cy="-28"
            r="193"
            stroke="url(#paint0_linear_29650_4192)"
            strokeWidth="100"
          />
          <defs>
            <linearGradient
              id="paint0_linear_29650_4192"
              x1="117.5"
              y1="21.5"
              x2="300"
              y2="183.887"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#28BE35" />
              <stop offset="1" stopColor="#5377D7" />
            </linearGradient>
          </defs>
        </svg>
      </Grid>
    </Grid>
  );
};

export default Features;
